<template>
  <div class="legend">
    <div class="row">
      <div class="past-activity"></div>
      <p>Completed</p>
    </div>
    <div class="row">
      <div class="current-activity"></div>
      <p>Current status</p>
    </div>
    <div class="row">
      <div class="next-activity"></div>
      <p>Next activity</p>
    </div>
    <div class="row">
      <div class="intervention"></div>
      <p>Intervention</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
